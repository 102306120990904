@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

body {
    background-color: '#2E2E2E';
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    /* border: 1px solid red; */
}

.loading-overlay {
    position: fixed;
    overflow-y: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 100;
}

@media (max-width: 768px) {

    .loading-overlay .spinner-ctn {
        position: relative;
        background-size: 75%;
        background-position: center;
        height: 15em;
        width: 15em;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}


.loading-overlay .spinner-ctn {
    position: relative;
    background-size: 75%;
    background-position: center;
    height: 15em;
    width: 15em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.spinner-ctn .spinner {
    position: absolute;
    background-image: url('https://res.cloudinary.com/dtnif6mzm/image/upload/v1658304980/Bedmar%20Music/LOGO%20PARTS/9_txs9fo.png');
    background-size: cover;
    top: 50%;
    left: 15%;
    height: 8.5em;
    width: 8.5em;
    animation: rotation 1000ms linear infinite;
    z-index: 0;
    /* border: 1px solid red; */
}

.spinner-letter {
    position: absolute;
    background-size: 155%;
    background-repeat: no-repeat;
    background-position-x: -94.5px;
    background-position-y: -38px;
    height: 10em;
    width: 10em;
    top: 75%;
    left: 76.5%;
    transform: translate(-50%, -50%);
    background-image: url('https://res.cloudinary.com/dtnif6mzm/image/upload/v1657805594/Bedmar%20Music/LOGO%20PARTS/7_ca7xgg.png');
    z-index: 1;
    background-color: black;
    /* border: 1px solid red */
}



/* 
@keyframes rotation {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
} */