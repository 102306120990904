.feature-ctn {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 100%;
}

@media (min-width: 1000px) {
    .feature-ctn {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        justify-content: center;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
    }
}