.form-ctn {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 5em;
}

form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: left;
    border-radius: 16px;
}

.label-ctn {
    font-size: .9em;
    font-weight: 600;
    margin-bottom: .5em;
    margin-top: 1em;
}

.formfield {
    width: 100%;
    margin-bottom: 1em;
    height: 3em;
    padding: 1em;
    background-color: rgb(235, 235, 235);
    border: none;
    outline: none;
}


.formfield::placeholder {
    opacity: .8;
}

.first-name {
    margin-right: 1em;
}

.formfield:active,
.formfield:focus {
    border: none;
    outline: none;
    background-color: rgb(223, 219, 219);
}

.label-ctn {
    width: 100%;
    text-align: left;
}

.formfield.message {
    height: 10em;
}

.names-ctn {
    width: 100%;
    display: flex;
}

.submit-btn {
    border: none;
    width: 100%;
    font-size: .8em;
    background-color: #BCA37D;
    color: white;
    transition: 200ms ease-in-out;
    text-transform: uppercase;
    padding: 1em;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #ffffff;
    color: #BCA37D;
    /* border: 1px solid #BCA37D; */
    outline: 2px solid #BCA37D;
    font-weight: bold;
}


.submitted {
    background-color: rgb(203, 255, 203);
    padding: 1.2em;
    margin-top: 5px;
}

@media (min-width: 1000px) {
    .form-ctn {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    form {
        width: 60%;
    }

    .formfield.message {
        height: 15em;
    }
}