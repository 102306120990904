.button {
    height: 40px;
    width: 135px;
    text-transform: uppercase;
    font-size: .6em;
    letter-spacing: .1em;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 1em 0;
    transition: all 250ms ease-in-out;
}

.button:active,
.button:hover {
    background-color: white !important;
    color: #BCA37D !important;
    border-radius: 6px;
}