.feature2-ctn {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 1em 0 1em
}

.feature2-ctn .title {
    text-align: left;
    margin: 1em 0;
    justify-content: left;
}

.feature2-ctn>.content {
    font-weight: bold;
    margin: .5em 0;
    font-size: 1.2em;
    padding: 0 3em 0 0em;
    line-height: 1.3em;


}

.img-ctn {
    height: 92vh;
    object-fit: cover;
    background-size: cover;
    width: clamp(50%, 400px, 100%);
    margin: auto;
    background-repeat: no-repeat;
    position: relative;
    transition: all 250ms ease;
}


.img-ctn.hover::after {
    content: '';
    position: absolute;
    background-color: #BCA37D;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 250ms ease
}

.bio {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    font-size: .75em;
    gap: .5em;
    color: #2e2e2e;
    text-align: center;
    font-weight: 600;
    padding: 2em 2.5em;
    line-height: 1.1em;
    margin: auto;
    letter-spacing: .02em;
    /* border: 1px solid red; */

    .bio-white {
        color: white
    }

}





@media (min-width: 430px) {
    .bio {
        font-size: .9em;
    }

}
@media (min-width: 500px) {
    .bio {
        font-size: .85em;
    }

}
@media (min-width: 1000px) {
    .feature2-ctn {
        display: grid;
        grid-template-columns: 1fr 60%;
        /* justify-content: center; */
        gap: 1em;
    }

    .feature2-ctn .title-ctn {
        display: flex;
        align-items: center;
    }

    .feature2-ctn .title {
        text-align: left;
        height: 200px;
        width: 11.8em;
        align-items: center;
        justify-content: left;
        padding: 0;
        margin: 0;
    }

    .feature2-ctn .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        line-height: 1.7em;
        text-align: right;
        padding: 0 0 0 1em;
    }

    .bio-ctn {
        display: flex;
        /* justify-content: right; */
        width: 90%;
        margin-left: auto;
        height: 75vh;
    }


    .bio {
        padding: 3em 4em;
        font-size: .9em;
    }

    .img-ctn {
        width: 100%;
        height: 105%;

    }
}

@media (min-width: 1100px) {
    .feature2-ctn {
        max-width: 1200px;
    }
    .bio {
        font-size: 1em;
    }
}


@media (min-width: 1500px) {
    .feature2-ctn {
        margin: auto
    }

    .bio {
        font-size: 1.2em;
    }
}