.section-ctn {
    padding: 1em 2.5em;
    margin: 0;
    width: 100%;
    overflow: hidden;
}

.section-ctn .title {
    text-transform: uppercase;
    width: 100%;
    height: clamp(2em, 10%, 10%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.3em;
    padding: 1em 0;
}

.section-ctn .category {
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    border: 1px solid;
    padding: .5em .5em .5em .5em;
    margin: auto;
    width: clamp(240px, 100%, 25.65em);
}

@media (min-width: 1000px) {
    .section-ctn {
        padding: 3em 7.5em;
    }

    .section-ctn .title {
        font-size: 2em;
        padding-bottom: 1em;
    }

    .section-ctn .category {
        padding: .5em;
        font-size: 1.5em;
        /* margin-bottom: 1em; */
        width: 100%;
    }
}

@media (min-width: 1000px) {
    .section-ctn .category {
        max-width: 1200px;
    }
}

@media (min-width: 1500px) {
    .section-ctn .category {
        margin: auto
    }
}