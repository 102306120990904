.dropdownmenu-ctn.open {
    text-transform: uppercase;
    background-color: transparent;
    color: white;
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
    transition: transform 250ms linear;
    transform: translateY(0%);
}

.dropdownmenu-ctn {
    position: absolute;
    width: 100%;
    transition: all 250ms linear;
    transform: translateY(200%);
    justify-content: space-between;
}


.dropdownmenu-ctn.closed {
    display: none;
    transform: translateY(200%);
    transition: transform 250ms linear;
}

.dropdown {
    display: flex;
    padding: 0 1em;
    justify-content: space-between;
}